import { AfterViewInit, Component, EventEmitter, Inject, OnInit, Output, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FolderTreeService } from '../../../modules/agents/pages/folder-tree/folder-tree.service';
import { IBrowserItemAction } from '../browser/browser-item-action';
import { IBrowserItem } from '../browser/browser-item';
import { Browser2Service } from '../../services/browser-2.service';
import { CrBrowserComponent, CrType } from '../cr-browser/cr-browser.component';
import { HierarchyAgent, HierarchyModal } from '../../models/ModalContracts/Hierarchymodal';
import { AuthService } from 'src/app/core/services/auth-service.service';

@Component({
  selector: 'app-hierarchy-modal',
  templateUrl: 'hierarchy-modal.component.html',
  styleUrls: ['hierarchy-modal.component.scss']
})
export class HierarchyModalComponent implements OnInit, AfterViewInit {
  @ViewChild('browser') browser: CrBrowserComponent;
  @Output() clickElement: EventEmitter<string> = new EventEmitter<string>();
  acts: Array<IBrowserItemAction> = [];
  browserItems: Array<IBrowserItem> = [];
  _dataBrowser: any;
  type: CrType = CrType.CHECKBOX;
  disableSelectionButton = true;

  constructor(public dialogRef: MatDialogRef<HierarchyModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: HierarchyModal,
              private readonly _folderTreeService: FolderTreeService,
              private readonly _auth: AuthService,
              private readonly browser2Service: Browser2Service) {
                
    this.browser2Service.finishedHierarchy.subscribe(this.openPreviousState.bind(this));
  }

  ngOnInit(): void {
    this.setActions();
    this.checkPreviousSelection();
  }

  ngAfterViewInit(): void {
    this.getCurrentAgentHierarchy();
  }

  selectFolder(id: string) {
    this.clickElement.emit(id);
  }

  applyHierarchySelection(): void {
    this.data.selectedIds.length = 0;
    this.browser.selectedIds().forEach(item => {
      this.data.selectedIds.push(item);
    });

    if (this.data.callback) this.data.callback(this.getSelectedAgents());
    this.dialogRef.close();
  }

  refreshActions(itemId: string) {
    this.browser.selection.id = itemId;
    this.browser.items = this.browser2Service.buildItemsHierarchy(this._dataBrowser, this.acts);
  }

  openPreviousState(): void {
    if (this.data.selectedIds.length > 1) {
      this.browser.locate(this.data.selectedIds.last());
    }
  }

  private getCurrentAgentHierarchy(): void {
    this._folderTreeService.getByAgentId(this._auth.userInfo.uid).subscribe(hierarchy => {
        if (hierarchy && hierarchy.value && hierarchy.value.length) {
          this._folderTreeService.browserMap.clear();
          const hierarchyList = hierarchy.value as Array<any>;
          // const agent = hierarchyList.first();
          // this.patchResult(agent);
          hierarchyList.forEach(agent => {
            this.patchResult(agent);
            this._folderTreeService.setMapHierarchy(agent);
          });
          this._dataBrowser = this._folderTreeService.browserMap;
          this.browserItems = this.browser2Service.buildItemsHierarchy(this._dataBrowser, this.acts);

          this.disableSelectionButton = false;
        }
      },
      error => {
        console.error(error);
      }
    );
  }

  private patchResult(agent: any): void {
    const recursion = function (agentObj: { id: string, groups: [], folder: { folderId: string } }) {
      agentObj.id = agentObj.folder.folderId;
      if (agentObj.groups && agentObj.groups.length) agentObj.groups.forEach(g => recursion(g));
    };
    recursion(agent);
  }

  private checkPreviousSelection(): void {
    if (this.data.selectedIds && this.data.selectedIds.length) {
      this.browser.checkSelection(this.data.selectedIds);
    }
  }

  private setActions(): void {
    this.acts = [
      {
        label: 'Seleccionar todos los agentes que contiene',
        fn: (id: string) => {
          this.browser.checkChildren(id);
          const agent = this.browserItems.find(a => a.id === id);
          this.browser.selection.id = id;
          if (agent) this.browserItems = this.browser2Service.buildItemsHierarchy(this._dataBrowser, this.acts);
          return 0;
        },
        type: 0,
        hidden: (id: string) => !this.browser.canShowSelectAllOption(id),
        toolTip: '',
        disabled: false
      },
      {
        label: 'Deseleccionar todos los agentes que contiene',
        fn: (id: string) => {
          this.browser.unCheckChildren(id);
          const agent = this.browserItems.find(a => a.id === id);
          this.browser.selection.id = id;
          if (agent) this.browserItems = this.browser2Service.buildItemsHierarchy(this._dataBrowser, this.acts);
          return 0;
        },
        type: 0,
        hidden: (id: string) => !this.browser.canShowDeselectAllOption(id),
        toolTip: '',
        disabled: false
      }
    ];
  }

  private getSelectedAgents = () => this.browser._items.filter(e => this.data.selectedIds.includes(e.id))
    .map(item => ({ id: item.id, name: item.title } as HierarchyAgent));
}

