import { Component, HostListener, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { APP_SETTINGS } from './configs/app-settings.config';
import { PermissionsService } from './core/services/permissions.service';
import './configs/array.config';
import { RouteHistory2Service } from './shared/routes/route-history2.service';
import { LoaderService } from './shared/components/loader/loader.service';
import { IdleUserService } from './shared/services/idle-user.service';
import { AuthService } from './core/services/auth-service.service';
import { MonitorService, TelemeteryNotification } from './shared/services/monitor.service';
import { WebVitalsService } from './shared/services/web-vitals.service';
import { NavigationEnd, Router } from '@angular/router';
import { FeatureFlagService } from './shared/services/feature-flag.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {

  constructor(private readonly translate: TranslateService, 
    private readonly _routerService: RouteHistory2Service,
    private readonly _permissions: PermissionsService, 
    private readonly _router: Router,
    public readonly loader: LoaderService,
    private readonly _idle: IdleUserService,
    private readonly _auth: AuthService,
    private readonly _monitor: MonitorService,
    private readonly _webVitals: WebVitalsService,
    private readonly ldFeatureFlag: FeatureFlagService) {

    this.translate.setDefaultLang(APP_SETTINGS.LANGUAGE.DEFAULT_LANGUAGE);
  }

  @HostListener('window:beforeunload') save() {
    this._routerService.saveHistoryState();
    this._permissions.savePermissions();
  }

  @HostListener('window:load') onload() {
    
    this._idle.start();
  }

  ngOnInit() {
    
    this._monitor.start(); //Start App Insigh monitor
    this._auth.loginListener(

      (user)=>{
        
        this._idle.reset();
        this.afterUserLoggin(user);
      }
    );

    this._router.events.subscribe(event => {

      if (event instanceof NavigationEnd) {
        
        if(event.url !== ''){

          this._webVitals.logWebVitals(event.url);
        }
      }
    });
    this.ldFeatureFlag.initialize();
  }

  /**Action to do when after user is login */
  afterUserLoggin(user:string){
    
    //Send to AppInsigh user
    this._monitor.logEvent(TelemeteryNotification.UserLogin, {
      userEmail: user
    });
  }
}
