export class User {
  //#region Required properties JSON
  id: string;
  name: string;
  lastName: string;
  jobName: string;
  photo: string;
  //#endregion
  nameInitials: string;
  isSelelected: boolean;
  queryId: Array<string>;
  permissions: UserPermissions;
  folderId: string;
  originations?: { denyPartialSave: boolean; };
  roles: string[]
}

export interface UserPermissions {
  id: string;
  name: string;
  permissions: Array<Module>;
}

export interface Module {
  id: string;
  moduleName: string;
  catalogs: Array<Catalog>;
}

export interface Catalog {
  id: string;
  catalogName: string;
  actions: Array<string>;
}

