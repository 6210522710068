import { from, Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { switchMap, take, tap, catchError, mergeMap, map } from 'rxjs/operators';
import { Instance, InstanceValues, ReducedContainer, ContainerValues } from '../models';
import { BaseService } from '../../core/services/base-service.service';
import { FeatureFlagEnum } from '../enums/featureFlag.enum';

@Injectable({
  providedIn: 'root'
})
export class OriginationsService extends BaseService {

  serverErrorCode = 500;
  loaderName: string;
  isInbox = false;
  protected baseUrl = window['__env'].APIS.ORIGINATIONS;

  runPartialFunction(instanceId: string, containerId: string, partialName, agentId: string): Observable<any> {

    const partialFunctionFlag = this.featureFlagService.getFlagValue(FeatureFlagEnum.PARTIAL_FUNCTION_INSTANCES_API);
    let runFunctionEndpoint = '';

    if (partialFunctionFlag) {

      runFunctionEndpoint = `${containerId}:executePartialFunction`;

      const baseUrl = `${this._env.APIS.INSTANCES_API}/organizations/${this._env.APIS.TENANTID}/agents/${agentId}/instances/${instanceId.toUpperCase()}/containers`;
      const body = { 'partialFunctionName': partialName };

      return super.post(runFunctionEndpoint, body, { baseUrl: baseUrl, loader: true });
    }
    else {

      runFunctionEndpoint = `instances/runPartialFunction`;

      return super.put(runFunctionEndpoint, {
        'instanceId': instanceId,
        'containerId': containerId,
        'partialFunctionName': partialName
      }, { loader: true });
    }
  }

  getInstance(instanceId: string, agentId: string): Observable<Instance> {

    const getFlagObservable = this.featureFlagService.getFlagObservable(FeatureFlagEnum.GET_INSTANCE_INSTANCES_API);

    return getFlagObservable.pipe(
      switchMap((flag) => {

        let baseUrl = '';

        if (flag) {

          baseUrl = `${this._env.APIS.INSTANCES_API}/organizations/${this._env.APIS.TENANTID}/agents/${agentId}/instances/${instanceId.toUpperCase()}`;
        }
        else {

          baseUrl = `${this.baseUrl}/instances/${instanceId.toUpperCase()}`;
        }

        return super.get(`?isInbox=${this.isInbox}`, { baseUrl: baseUrl }).pipe(

          tap(response => {

            mergeMap(_ => from(response().pipe(catchError(error => of(`Error: ${error}`)))))

            if (!response && response.code !== this.serverErrorCode) {

              setTimeout(() => {

                this.getInstance(instanceId, agentId);
              });
            }
          })
        ).pipe(map(result => {

          // Need to check if result contains values property, if not, it means that the instance is from old API
          if (result.values === undefined) {

            result = this.buildInstanceStructure(result, instanceId, agentId);
          }

          return result;
        }));
      })
    )
  }

  getInstanceDocument(instanceId: string): Observable<any> {
    return super.get(`Template/${ instanceId }/GetPdfFromInstanceId`, { loaderName: 'documents_loader' });
  }

  getPDFOriginationDocument(idDocument: string): Observable<any> {

    return from(this._auth.accessToken).pipe(
      take(1),
      switchMap(
        (token)=>{

          const httpClient = new HttpClient(this._httpBackend);
          const headers = new HttpHeaders({
            'Authorization': `Bearer ${ token }`,
            'Ocp-Apim-Subscription-Key': window['__env'].SUBSCRIPTION_KEY
          });
          return httpClient.get(`${ this.baseUrl }/Template/${ idDocument }/GetPDF`, { headers: headers, responseType: 'blob' });
        }
      )
    )
  }

  getReducedContainer(instanceId: string, containerId: string, agentId: string): Observable<any> {

    const getFlagObservable = this.featureFlagService.getFlagObservable(FeatureFlagEnum.GET_CONTAINER_INSTANCES_API);

    return getFlagObservable.pipe(
      switchMap((flag) => {

        if (flag) {

          const baseUrl = `${this._env.APIS.INSTANCES_API}/organizations/${this._env.APIS.TENANTID}/agents/${agentId}/instances/${instanceId.toUpperCase()}/containers`;

          return super.get(`${containerId}`, { baseUrl: baseUrl, loader: true }).pipe(map(result => {

            result = this.buildContainerStructure(result);

            return result;
          }));
        }
        else {

          return super.get(`instances/${instanceId.toUpperCase()}/${containerId}?raw=true`, { loader: true });
        }
      })
    )
  }

  createInstance(definitionId: string): Observable<any> {

    const getFlagObservable = this.featureFlagService.getFlagObservable(FeatureFlagEnum.CREATE_INSTANCE_INSTANCES_API);

    return getFlagObservable.pipe(
      switchMap((flag) => {

        let dynamicBaseUrl = this.baseUrl;

        if (flag) {

          dynamicBaseUrl = `${this._env.APIS.INSTANCES_API}/organizations/${this._env.APIS.TENANTID}/products/${definitionId}`;
        }
        
        return super.post(`instances`,
          {
            definitionId: definitionId
          },
          {
            baseUrl: dynamicBaseUrl,
            loaderName: this.loaderName
          }
        );
      })
    )
  }

  partialSave(instanceId: string, containerId: string, values: any, showLoader = false, loaderName?: string): Observable<any> {
    const json = {
      instanceId: instanceId.toUpperCase(),
      containerId: containerId,
      values: values
    };
    return super.put(`instances/putValue`, json, { baseUrl: this.baseUrl, loader: showLoader, loaderName: loaderName });
  }

  processInstance(instanceId: string, containerId: string, agentId: string): Observable<any> {

    const processInstanceFlag = this.featureFlagService.getFlagValue(FeatureFlagEnum.PROCESS_INSTANCES_API);
    const loaderOptions = {
      loader: true,
      loaderMessage: 'Procesando'
    }

    if (processInstanceFlag) {

      const baseUrl = `${this._env.APIS.INSTANCES_API}/organizations/${this._env.APIS.TENANTID}/agents/${agentId}/instances/${instanceId.toUpperCase()}/containers`;
      const processEndpoint = `${containerId}:process`;

      return super.post(processEndpoint, {}, { baseUrl: baseUrl, loaderName: this.loaderName })
        .pipe(
          map(() => {
            return {
              // set status to 0 to indicate success and it is used in the component to show the success message
              status: 0
            }
          }),
          catchError(() => {
            return of({
              // set status 3 to indicate an error and not processed
              status: 3
            })
          })
        );
    }
    else {

      return super.put(`instances/putProcessInstance`, { instanceId: instanceId.toUpperCase(), containerId: containerId }, loaderOptions);
    }
  }

  getCatalogByParentId(resource: string, parentId: string, instanceId?: string, dataType?: string): Observable<any> {
    const loaderConfig = { baseUrl: this.baseUrl, loader: false };
    if (instanceId) {
      return super.get(`catalogs/${ resource }?instanceId=${ instanceId }&dataType=${ dataType }&parentKey=${ parentId }`,
        loaderConfig);
    } else {
      return super.get(`catalogs/${ resource }?parentKey=${ parentId }`, loaderConfig);
    }
  }

  getCatalog(resource: string, instanceId?: string, dataType?: string): Observable<any> {
    const loaderConfig = { baseUrl: this.baseUrl, loader: false };
    if (instanceId) {
      return super.get(`catalogs/${ resource }?instanceId=${ instanceId }&dataType=${ dataType }`, loaderConfig);
    } else {
      return super.get(`catalogs/${ resource }`, loaderConfig);
    }
  }

  getByUserProfile(instanceId: string): Observable<any> {
    return super.get(`instances/GetByUserProfile/${ instanceId.toUpperCase() }`);
  }

  getUTFDate(): Observable<any> {
    return super.get(`Instances/datetime/utc`);
  }

  getTreeByUserProfile(instanceId: string): Observable<any> {
    return super.get(`instances/GetTreeByUserProfile/${ instanceId.toUpperCase() }/?buzon=${ this.isInbox }`, { loader: true });
  }

  assign(instanceId: string): Observable<any> {

    const inboxAssignment = this.featureFlagService.getFlagValue(FeatureFlagEnum.INBOX_ASSIGNMENT_INSTANCES_API);
    const loaderName = 'assignDocumentToAgent';
    let assignmentEndpoint = '';

    if (inboxAssignment) {

      const baseUrl = `${this._env.APIS.INSTANCES_API}/organizations/${this._env.APIS.TENANTID}/agents/${this._auth.userInfo.uid}/instances`;
      assignmentEndpoint = `${instanceId.toUpperCase()}:inboxAssignment`;

      return super.post(assignmentEndpoint, {}, { baseUrl: baseUrl, loaderName });
    }
    else {

      assignmentEndpoint = 'Instances/putChangeInboxAssignment';

      return super.put(assignmentEndpoint, { instanceId: instanceId.toUpperCase() }, { loaderName });
    }
  }

  private buildInstanceStructure(instance: any, instanceId: string, agentId: string) {

    let newInstance = new Instance();
    let values = new InstanceValues();

    // Set always status 0, because this is evaluated with old instances response when set values in change status logic (0 = OK)
    // this is possible because the new instances response all the time is successful otherwise this is caught by the mergeMap
    newInstance.status = 0;
    newInstance.instanceId = instanceId;
    newInstance.agentId = agentId;
    newInstance.isOwner = instance.isOwner;

    values.schemaTitle = instance.schemaTitle;
    values.headerValues = instance.headerValues;
    values.structure = instance.structure;
    values.dataTypes = instance.dataTypes;
    values.containers = instance.containers;
    values.documentId = instance.documentId;
    values.prefix = instance.prefix;
    values.suffix = instance.suffix;
    values.uid = instance.uid;
    values.initialDate = instance.initialDate;

    newInstance.values = values;

    return newInstance;
  }

  private buildContainerStructure(container: any) {

    let newContainer =  new ReducedContainer();
    let values = new ContainerValues();

    newContainer.containerId = container.containerId;
    newContainer.customStatusContainer = container.customStatusContainer;
    newContainer.instanceId = container.instanceId;
    newContainer.isHiddenClose = container.isHiddenClose;
    newContainer.status = container.status;
    newContainer.tenantId = container.tenantId;

    values.containerId = container.containerId;
    values.customStatus = container.customStatus;
    values.customStatusInfo = container.customStatusInfo;
    values.data = container.data;
    values.dataFront = container.dataFront;
    values.isHiddenClose = container.isHiddenClose;
    values.status = container.status;
    values.title = container.title;

    newContainer.values = values;

    return newContainer;
  }
}
