import { Observable } from 'rxjs';

export class AuthAccount{
  
  uid: string;
  userName?: string;
  name?: any;

}

export abstract class AuthService {
  
  abstract login(): void;

  abstract logout(): void;

  abstract get userInfo(): AuthAccount ;

  abstract get userId(): string;

  abstract get accessToken(): Promise<string>; 

  abstract get isAuthenticated(): boolean;

  abstract get LoggedInUserEmail(): string;
  
  abstract tokenValue():Observable<string>;

  abstract showAuthorizationError():void;

  abstract loginListener(callback): void;

  abstract clearLocalStorage(): void; 
}

